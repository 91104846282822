import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Container,
  Paper,
  Divider,
} from '@mui/material';
import SupabaseService from '../services/SupabaseService';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/Layout';
import '../styles/Register.css';

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('');
  const { state, isInitialized } = useAuth();
  const navigate = useNavigate();

  const handleRegister = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(null);
    setErrorMessage('');

    const result = await SupabaseService.registerUser(email);

    if (result && 'error' in result && result.error) {
      if (result.error.message.includes('ineligible')) {
        setErrorMessage(
          <>
            {result.error.message}{' '}
            <Link
              href="https://polychord.io/contact.php"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'red' }}
            >
              here.
            </Link>
            .
          </>,
        );
      } else {
        setErrorMessage(result.error.message);
      }
    } else {
      setSuccess(
        'If you have an account with us, you will receive a registration link. Please check your inbox.',
      );
    }
    setLoading(false);
  };

  const handleLogoutAndRedirect = async () => {
    await SupabaseService.logout();
    navigate('/register');
  };

  const renderRegisterForm = (): JSX.Element => (
    <Container maxWidth="xs">
      <Paper elevation={1} sx={{ padding: 4, marginTop: 8, borderRadius: 2 }}>
        <Typography variant="h5" align="center" fontWeight="bold" gutterBottom>
          Register for PolyTrack
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <form onSubmit={handleRegister}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              InputProps={{
                sx: { borderRadius: 1 },
              }}
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                paddingY: 1.5,
                borderRadius: 2,
                fontWeight: 'bold',
                backgroundColor: '#5865f2',
                '&:hover': { backgroundColor: '#4b55c1' },
              }}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Register'}
            </Button>
            {success && (
              <Typography color="success.main" align="center" mt={1}>
                {success}
              </Typography>
            )}
            {errorMessage && (
              <Typography color="error.main" align="center" mt={1}>
                {errorMessage}
              </Typography>
            )}
            <Divider sx={{ marginY: 2 }} />
            <Typography align="center" variant="body2">
              <Link
                href="/login"
                underline="hover"
                sx={{
                  color: 'inherit',
                  '&:hover': { color: '#5865f2' },
                }}
              >
                Already have an account? Login here
              </Link>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Container>
  );

  const renderRegistration = (): JSX.Element => (
    <Container maxWidth="xs">
      <Paper elevation={1} sx={{ padding: 4, marginTop: 8, borderRadius: 2 }}>
        <Typography variant="h5" align="center" fontWeight="bold" gutterBottom>
          Registration
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Typography align="center" variant="body2" sx={{ marginY: 2 }}>
          You already have an account. If you wish to register a new account,
          please{' '}
          <Button
            onClick={handleLogoutAndRedirect}
            sx={{
              background: 'none',
              color: '#5865f2',
              border: 'none',
              textDecoration: 'underline',
              padding: 0,
              minWidth: 'auto',
              '&:hover': { color: '#4b55c1', background: 'transparent' },
            }}
          >
            log out.
          </Button>
          .
        </Typography>
      </Paper>
    </Container>
  );

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="register">
        <div className="register-content">
          {state === 'logged-in' ? renderRegistration() : renderRegisterForm()}
        </div>
        <video
          autoPlay
          muted
          loop
          disablePictureInPicture
          poster=""
          className="register-background"
        >
          <source
            src="/assets/digital-animation.mp4"
            type="video/mp4; codecs=avc1.4D401E"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </Layout>
  );
};

export default Register;
