import React, { useState, useRef, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import Layout from '../components/Layout';
import useDashboardData from '../hooks/useDashboardData';
import Map from '../components/Map';
import '../styles/Dashboard.css';
import { MapHandleZoom } from '../components/Map';
import supabaseServiceInstance from '../services/SupabaseService';
import { saveAs } from 'file-saver';

export type PointOfInterest = {
  id: string;
  Latitude: number;
  Longitude: number;
  createdAt: string | number;
  rank: number;
  stdError: number;
  dateFirstAppeared: Date;
  company: string;
  notes: string;
  status: string;
};

const Dashboard: React.FC = () => {
  // Retrieve session data and POI data using useDashboardData()
  const supabase = supabaseServiceInstance.getClient();
  const { session, pointsOfInterest } = useDashboardData();
  const email = session?.user?.email;
  const mapRef = useRef<MapHandleZoom>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null,
  );
  const [fadeClass, setFadeClass] = useState<string>('fade-in');

  // Format points of interest data, excluding Supabase's internal fields `createdAt` and `ID`
  const [formattedPoints, setPoints] = useState<PointOfInterest[]>([]);

  useEffect(() => {
    const formatted = pointsOfInterest
      .map((poi) => ({
        id: poi.id.toString(),
        Latitude: Number(poi.Latitude),
        Longitude: Number(poi.Longitude),
        createdAt: poi.created_at,
        rank: Number(poi.Rank),
        stdError: Number(poi['Standard Deviation of Error (m)']),
        dateFirstAppeared: new Date(poi['Date POI First appeared']),
        company: String(poi['Company']),
        notes: String(poi.Notes),
        status: String(poi.Status),
      }))
      .sort((a, b) => a.rank - b.rank);

    setPoints(formatted);
  }, [pointsOfInterest]);

  const minRank = Math.min(...formattedPoints.map((poi) => poi.rank));
  const maxRank = Math.max(...formattedPoints.map((poi) => poi.rank));

  function Points({ currentPoints }: { currentPoints: PointOfInterest[] }) {
    // Display POI data in table format
    const getStatusColor = (status: string): string => {
      switch (status) {
        case 'Not Started':
          return 'red';
        case 'In Progress':
          return 'orange';
        case 'Completed':
          return 'green';
        default:
          return 'gray';
      }
    };

    return (
      <>
        {currentPoints &&
          currentPoints.map((point) => (
            <tr key={point.id} className="flex-row">
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={'../assets/location_pin.png'}
                    alt={point.rank.toString()}
                    className="location-pin"
                    onClick={() => {
                      mapRef.current?.zoomToLocation(
                        point.Latitude,
                        point.Longitude,
                        point,
                      );
                      setTimeout(() => {
                        mapRef.current?.toggleTab('details');
                      }, 1000);
                    }}
                  />
                  <span>{point.rank}</span>
                </div>
              </td>
              <td>{point.Latitude}</td>
              <td>{point.Longitude}</td>
              <td>+/- {point.stdError.toFixed(2)} m</td>
              <td>{point.dateFirstAppeared.toLocaleDateString()}</td>
              <td>
                <select
                  className="status"
                  value={point.status}
                  onChange={(e) => updateStatus(e.target.value, point.id)}
                  style={{
                    backgroundColor: getStatusColor(point.status),
                    color: 'white',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  <option style={{ backgroundColor: 'red', color: 'white' }}>
                    Not Started
                  </option>
                  <option style={{ backgroundColor: 'orange', color: 'white' }}>
                    In Progress
                  </option>
                  <option style={{ backgroundColor: 'green', color: 'white' }}>
                    Completed
                  </option>
                </select>
              </td>
              <td>
                <button
                  className="notes-toggle"
                  onClick={() => {
                    mapRef.current?.zoomToLocation(
                      point.Latitude,
                      point.Longitude,
                      point,
                    );
                    setTimeout(() => {
                      mapRef.current?.toggleTab('notes');
                    }, 1000);
                  }}
                >
                  View Notes
                </button>
              </td>
            </tr>
          ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }: { itemsPerPage: number }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = formattedPoints.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(formattedPoints.length / itemsPerPage);

    const handlePageClick = (event: { selected: number }) => {
      const newOffset =
        (event.selected * itemsPerPage) % formattedPoints.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Points currentPoints={currentItems} />
        <tr>
          <td colSpan={7}>
            <div className="dashboard-toggle">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName="react-paginate"
                activeClassName="selected"
              />
              <button className="dashboard-download" onClick={downloadCSV}>
                Download CSV ⤓
              </button>
            </div>
          </td>
        </tr>
      </>
    );
  }

  const downloadCSV = () => {
    if (!formattedPoints.length) {
      console.error('No points of interest to download.');
      return;
    }

    const csvHeaders = [
      'ID,Latitude,Longitude,Rank,Radial Uncertainty (m),Date First Appeared,Company,Notes,Status',
    ];

    const csvRows = formattedPoints.map((point) => {
      return [
        point.id,
        point.Latitude,
        point.Longitude,
        point.rank,
        point.stdError.toFixed(2),
        point.dateFirstAppeared.toISOString().split('T')[0],
        point.company,
        point.notes.replace(/,/g, ';'),
        point.status,
      ].join(',');
    });

    const csvContent = [csvHeaders, ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'points_of_interest.csv');
  };

  async function updateStatus(newStatus: string, id: string) {
    //function for updating the status of the POI
    try {
      const { error } = await supabase
        .from('points-of-interest')
        .update({ Status: newStatus })
        .eq('id', id);

      if (error) {
        console.error('Error updating status:', error.message);
        return;
      }
      setPoints((prevPoints) =>
        prevPoints.map((poi) =>
          poi.id === id ? { ...poi, status: newStatus } : poi,
        ),
      );

      setConfirmationMessage('Status updated successfully!');
      setFadeClass('fade-in');

      // Hide confirmation message after 3 seconds
      setTimeout(() => {
        setFadeClass('fade-out');
      }, 3000);
      console.log('Status updated successfully:', newStatus);
    } catch (err) {
      console.error('Error in updateStatus:', err);
    }
  }

  return (
    <Layout>
      {email && (
        <h4 className="Greeting">
          <strong>Hello, {email}</strong>
        </h4>
      )}
      {(email && (
        <h4 className="GreetingParagraph">
          Maps and data show track Points of Interest (POIs) i.e. detected
          issues that require inspection. The smaller the ranking number, the
          greater the severity of the issue/POI. Since GNSS locational
          information is inherently imperfect, we include the radial uncertainty
          for each POI, which describes the radial/circular distance around the
          POI marker that the associated issue could be located in reality.
        </h4>
      )) || <h1>Please Log In to Access the Dashboard</h1>}

      {session && (
        <>
          <div className="map" ref={mapContainerRef}>
            <Map
              ref={mapRef}
              pointsOfInterest={formattedPoints}
              minRank={minRank}
              maxRank={maxRank}
              email={session?.user?.email || ''}
            />
          </div>
          {confirmationMessage && (
            <div className={`confirmation-message ${fadeClass}`}>
              {confirmationMessage}
            </div>
          )}
          <div className="container">
            <div className="dataView">
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>Rank</strong>
                    </th>
                    <th>
                      <strong>Latitude</strong>
                    </th>
                    <th>
                      <strong>Longitude</strong>
                    </th>
                    <th>
                      <strong>Radial Uncertainty (m)</strong>
                    </th>
                    <th>
                      <strong>Date First Appeared</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th>
                      <strong>Notes</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <PaginatedItems itemsPerPage={10} />
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Dashboard;
