import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/Map.css';
import '../styles/Map_notes.css';
import supabaseServiceInstance from '../services/SupabaseService';
import { PointOfInterest } from '../pages/Dashboard';

// Check for .env variable to render the map
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
if (!mapboxgl.accessToken) {
  throw new Error(
    'Please set a valid REACT_APP_MAPBOX_TOKEN in your .env file',
  );
}

interface MapProps {
  pointsOfInterest: PointOfInterest[];
  minRank: number;
  maxRank: number;
  email: string;
}
export interface MapHandleZoom {
  zoomToLocation: (
    latitude: number,
    longitude: number,
    poi: PointOfInterest,
  ) => void;
  toggleTab: (tab: 'details' | 'notes') => void;
}

const INITIAL_ZOOM = 9;

// RGB color stops for a full spectrum gradient from red to blue
const colorStops = [
  [255, 0, 0],
  [255, 165, 0],
  [255, 255, 0],
  [0, 128, 0],
  [0, 0, 255],
];

// Function to get the color for a rank based on the color stops
const getColorForRank = (rank: number, minRank: number, maxRank: number) => {
  const ratio = (rank - minRank) / (maxRank - minRank);
  const segment = Math.floor(ratio * (colorStops.length - 1));
  const segmentRatio = (ratio * (colorStops.length - 1)) % 1;

  const startColor = colorStops[segment];
  const endColor = colorStops[Math.min(segment + 1, colorStops.length - 1)];

  const r = Math.round(
    startColor[0] * (1 - segmentRatio) + endColor[0] * segmentRatio,
  );
  const g = Math.round(
    startColor[1] * (1 - segmentRatio) + endColor[1] * segmentRatio,
  );
  const b = Math.round(
    startColor[2] * (1 - segmentRatio) + endColor[2] * segmentRatio,
  );

  return `rgb(${r},${g},${b})`;
};

// Function to format rail type names
const formatRailType = (railType: string) => {
  return railType.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
};

// Helper function to generate popup content
const generatePopupContent = (poi: PointOfInterest) => {
  return `
    <div id="map-popup">
      <div><b>Rank:</n> ${poi.rank}</div>
      <div>Date First Appeared: ${new Date(
        poi.dateFirstAppeared,
      ).toLocaleDateString()}</div>
      <div>Company: ${poi.company}</div>
    </div>
  `;
};

const Map = forwardRef<MapHandleZoom, MapProps>(
  ({ pointsOfInterest, minRank, maxRank, email }, ref) => {
    const supabase = supabaseServiceInstance.getClient();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const [zoom, setZoom] = useState(INITIAL_ZOOM);
    const [selectedPoint, setSelectedPoint] = useState<PointOfInterest | null>(
      null,
    );

    const [showNotes, setShowNotes] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [notes, setNotes] = useState<
      Array<{
        text: string;
        author: string;
        timestamp: Date;
      }>
    >([]);
    const [activeTab, setActiveTab] = useState<'details' | 'notes'>('details');

    const handleAddNote = async () => {
      if (isNoteOnCooldown) {
        setNoteAddedMessage(
          `Please wait ${cooldownTime} seconds before adding another note`,
        );
        setTimeout(() => setNoteAddedMessage(null), 3000);
        return;
      }

      if (newNote.trim() && selectedPoint) {
        const newNoteObj = {
          text: newNote,
          author: email,
          timestamp: new Date(),
        };

        try {
          const currentNotes = [...notes, newNoteObj];
          const { error } = await supabase
            .from('points-of-interest')
            .update({
              Notes: JSON.stringify(currentNotes),
            })
            .eq('id', selectedPoint.id);

          if (error) {
            console.error('Error updating notes:', error.message);
            setNoteAddedMessage('Error adding note. Please try again.');
            setTimeout(() => setNoteAddedMessage(null), 3000);
            return;
          }

          setNotes(currentNotes);
          setNewNote('');
          setNoteAddedMessage('Note added successfully!');
          setTimeout(() => setNoteAddedMessage(null), 3000);
          startCooldown();
        } catch (err) {
          console.error('Error in handleAddNote:', err);
          setNoteAddedMessage('Error adding note. Please try again.');
          setTimeout(() => setNoteAddedMessage(null), 3000);
        }
      }
    };

    useEffect(() => {
      if (selectedPoint?.notes) {
        // Parse existing notes from the string format
        const existingNotes = (
          JSON.parse(
            selectedPoint.notes === 'null' ? '[]' : selectedPoint.notes,
          ) as { text: string; author: string; timestamp: Date }[]
        )
          .filter((note) => note.text && note.author && note.timestamp)
          .map((note) => ({
            text: note.text,
            timestamp: new Date(note.timestamp),
            author: note.author,
          }))
          .reverse(); // Reverse the array to show newest first

        setNotes(existingNotes);
      } else {
        setNotes([]);
      }
    }, [selectedPoint]);

    // pop up message for note
    const [noteAddedMessage, setNoteAddedMessage] = useState<string | null>(
      null,
    );
    const [isNoteOnCooldown, setIsNoteOnCooldown] = useState(false);
    const [cooldownTime, setCooldownTime] = useState(30);
    const [cooldownInterval, setCooldownInterval] =
      useState<NodeJS.Timeout | null>(null);

    const startCooldown = () => {
      setIsNoteOnCooldown(true);
      setCooldownTime(30);

      const interval = setInterval(() => {
        setCooldownTime((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsNoteOnCooldown(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      setCooldownInterval(interval);
    };

    // Cleanup cooldown interval on unmount
    useEffect(() => {
      return () => {
        if (cooldownInterval) {
          clearInterval(cooldownInterval);
        }
      };
    }, [cooldownInterval]);

    const getInitialCenter = (email: string): [number, number] => {
      const domain = email.split('@')[1];
      switch (domain) {
        case 'westmidlandsmetro.com':
          return [-1.9219, 52.5079];
        case 'edinburghtrams.com':
          return [-3.1734, 55.936];
        default:
          return [-1.9219, 52.5079];
      }
    };

    const [INITIAL_CENTER] = useState<[number, number]>(
      getInitialCenter(email),
    );

    const popupRef = useRef<mapboxgl.Popup | null>(null);

    const showTab = (tab: 'details' | 'notes') => {
      setShowNotes(true);
      setActiveTab(tab);
    };

    useImperativeHandle(ref, () => ({
      zoomToLocation: (
        latitude: number,
        longitude: number,
        poi: PointOfInterest,
      ) => {
        setTimeout(() => {
          mapRef.current?.stop();
          mapRef.current?.flyTo({
            center: [longitude, latitude],
            zoom: 18,
            essential: true,
          });
        }, 1000);
        setSelectedPoint(poi); // Zoom to location and see the information about the point
        if (popupRef.current) {
          popupRef.current.remove();
        }
        const newPopup = new mapboxgl.Popup({
          closeOnClick: true,
          offset: [0, -25],
        })
          .setLngLat([longitude, latitude])
          .setHTML(generatePopupContent(poi))
          .addTo(mapRef.current!);
        popupRef.current = newPopup;
      },
      toggleTab: showTab,
    }));
    const [currentCenter, setCurrentCenter] =
      useState<[number, number]>(INITIAL_CENTER);

    // New state variables for toggles
    const [showHeatmap, setShowHeatmap] = useState(true);
    const [showRailTypes, setShowRailTypes] = useState(true);
    const [mapStyle, setMapStyle] = useState(
      'mapbox://styles/mapbox/streets-v12',
    );

    const isSatelliteView =
      mapStyle === 'mapbox://styles/mapbox/satellite-streets-v12';

    // State variable for selected rail types
    type RailType =
      | 'major_rail'
      | 'minor_rail'
      | 'light_rail'
      | 'subway'
      | 'tram'
      | 'funicular'
      | 'monorail'
      | 'narrow_gauge'
      | 'preserved';

    /* eslint-disable camelcase */
    // prettier-ignore
    const initialRailTypes: Record<RailType, boolean> = {
      major_rail: false,
      minor_rail: true,
      light_rail: true,
      subway: true,
      tram: true,
      funicular: true,
      monorail: true,
      narrow_gauge: true,
      preserved: true,
    };
    /* eslint-enable camelcase */

    const [selectedRailTypes, setSelectedRailTypes] =
      useState<Record<RailType, boolean>>(initialRailTypes);
    const [showRailTypeOptions, setShowRailTypeOptions] = useState(false);

    // Function to add custom layers to the map
    const addCustomLayers = useCallback(() => {
      if (!mapRef.current) return;

      // Add 'poi-heat' source
      if (!mapRef.current.getSource('poi-heat')) {
        mapRef.current!.addSource('poi-heat', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: pointsOfInterest.map((poi) => ({
              type: 'Feature',
              properties: {
                intensity: poi.stdError,
              },
              geometry: {
                type: 'Point',
                coordinates: [poi.Longitude, poi.Latitude],
              },
            })),
          },
        });
      }

      // Add heatmap layer
      if (!mapRef.current.getLayer('poi-heat')) {
        mapRef.current!.addLayer({
          id: 'poi-heat',
          type: 'heatmap',
          source: 'poi-heat',
          paint: {
            // Increase weight based on standard deviation
            'heatmap-weight': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.1,
              10,
              ['/', ['get', 'intensity'], 5],
              15,
              ['/', ['get', 'intensity'], 5],
            ],
            // Increase intensity as zoom level increases
            'heatmap-intensity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.3,
              15,
              2,
            ],
            // Color gradient
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'rgb(103,169,207)',
              0.4,
              'rgb(209,229,240)',
              0.6,
              'rgb(253,219,199)',
              0.8,
              'rgb(239,138,98)',
              1,
              'rgb(178,24,43)',
            ],
            // Adjust radius with zoom
            'heatmap-radius': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              0,
              20, // Large enough to show density
              10,
              30, // Medium zoom
              15,
              ['*', ['get', 'intensity'], 8], // Start using stdError
              20,
              ['*', ['get', 'intensity'], 13], // Max zoom
            ],
            'heatmap-opacity': 0.8,
          },
        });
      }

      // Add custom rail layer
      if (!mapRef.current.getLayer('roads-layer')) {
        mapRef.current!.addLayer(
          {
            id: 'roads-layer',
            type: 'line',
            source: 'composite',
            'source-layer': 'road',
            filter: ['in', 'class', ...Object.keys(initialRailTypes)],
            // Add colors for each type of rail, with earth brown as fallback
            paint: {
              'line-color': [
                'match',
                ['get', 'class'],
                'major_rail',
                '#FF4500', // vivid orange-red
                'minor_rail',
                '#9400D3', // dark violet
                'light_rail',
                '#FF1493', // deep pink
                'subway',
                '#FF8C00', // dark orange
                'tram',
                '#00CED1', // dark turquoise
                'funicular',
                '#FFD700', // gold
                'monorail',
                '#8A2BE2', // blue violet
                'narrow_gauge',
                '#C71585', // medium violet red
                'preserved',
                '#32CD32', // lime green
                '#B22222', // Fallback: earth brown
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                12,
                10,
                22,
                20,
              ],
            },
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
          },
          'waterway',
        );

        // Add click event listener for displaying popup with rail type
        mapRef.current!.on('click', 'roads-layer', (e) => {
          const features = e.features;

          if (features && features.length > 0) {
            const railType = features[0].properties?.class || 'N/A';

            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`<p><b>Rail type: ${formatRailType(railType)}</b></p>`)
              .addTo(mapRef.current!);
          }
        });

        // Change the cursor to pointer when hovering over the roads layer
        mapRef.current!.on('mouseenter', 'roads-layer', () => {
          mapRef.current!.getCanvas().style.cursor = 'pointer';
        });

        mapRef.current!.on('mouseleave', 'roads-layer', () => {
          mapRef.current!.getCanvas().style.cursor = '';
        });
      }
      // eslint-disable-next-line
    }, [pointsOfInterest]);

    useEffect(() => {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current!,
        style: mapStyle,
        center: INITIAL_CENTER,
        zoom: INITIAL_ZOOM,
      });
      const geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showAccuracyCircle: true,
      });
      mapRef.current.addControl(geolocateControl, 'top-right');
      mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      mapRef.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');

      mapRef.current.on('load', () => {
        addCustomLayers();

        // Set initial visibility
        if (mapRef.current?.getLayer('poi-heat')) {
          mapRef.current.setLayoutProperty(
            'poi-heat',
            'visibility',
            showHeatmap ? 'visible' : 'none',
          );
        }
        if (mapRef.current?.getLayer('roads-layer')) {
          mapRef.current.setLayoutProperty(
            'roads-layer',
            'visibility',
            showRailTypes && !isSatelliteView ? 'visible' : 'none',
          );
          const selectedTypes = Object.keys(selectedRailTypes).filter(
            (type) => selectedRailTypes[type as RailType],
          );
          mapRef.current.setFilter('roads-layer', [
            'in',
            'class',
            ...selectedTypes,
          ]);
        }

        // Add markers for points of interest
        pointsOfInterest.forEach((poi) => {
          if (poi.Latitude && poi.Longitude) {
            // Calculate color based on rank using gradient
            const color = getColorForRank(poi.rank, minRank, maxRank);

            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              generatePopupContent(poi),
            );
            const marker = new mapboxgl.Marker({ color })
              .setLngLat([poi.Longitude, poi.Latitude])
              .setPopup(popup)
              .addTo(mapRef.current!);

            // Hidden by default
            marker.getElement().style.display = 'none';

            // Show/hide markers based on zoom level
            mapRef.current!.on('zoom', () => {
              const currentZoom = mapRef.current!.getZoom();
              marker.getElement().style.display =
                currentZoom > 11 ? 'block' : 'none';
            });

            marker.getElement().addEventListener('mouseenter', () => {
              mapRef.current!.getCanvas().style.cursor = 'pointer';
            });

            marker.getElement().addEventListener('mouseleave', () => {
              mapRef.current!.getCanvas().style.cursor = '';
            });
            marker.getElement().addEventListener('click', () => {
              if (popupRef.current) {
                popupRef.current.remove();
              }
              setSelectedPoint(poi);
              popupRef.current = popup;
              showTab('details');
            });
          }
        });
      });
      // Update map center and zoom state on map move
      mapRef.current.on('move', () => {
        const mapCenter = mapRef.current!.getCenter();
        const mapZoom = mapRef.current!.getZoom();
        setCurrentCenter([mapCenter.lng, mapCenter.lat]);
        setZoom(mapZoom);
      });

      // Cleanup on component unmount
      return () => {
        if (popupRef.current) {
          popupRef.current.remove();
        }
        mapRef.current!.remove();
      };
      // eslint-disable-next-line
    }, [
      pointsOfInterest,
      minRank,
      maxRank,
      INITIAL_CENTER,
      mapStyle,
      addCustomLayers,
    ]);

    // Effect to handle map style changes
    useEffect(() => {
      if (!mapRef.current) return;

      mapRef.current.setStyle(mapStyle);

      mapRef.current.once('styledata', () => {
        addCustomLayers();
        // Re-apply layer visibility settings
        if (mapRef.current?.getLayer('poi-heat')) {
          mapRef.current.setLayoutProperty(
            'poi-heat',
            'visibility',
            showHeatmap ? 'visible' : 'none',
          );
        }
        if (mapRef.current?.getLayer('roads-layer')) {
          if (showRailTypes && !isSatelliteView) {
            const selectedTypes = Object.keys(selectedRailTypes).filter(
              (type) => selectedRailTypes[type as RailType],
            );
            if (selectedTypes.length > 0) {
              mapRef.current.setLayoutProperty(
                'roads-layer',
                'visibility',
                'visible',
              );
              mapRef.current.setFilter('roads-layer', [
                'in',
                'class',
                ...selectedTypes,
              ]);
            } else {
              mapRef.current.setLayoutProperty(
                'roads-layer',
                'visibility',
                'none',
              );
            }
          } else {
            mapRef.current.setLayoutProperty(
              'roads-layer',
              'visibility',
              'none',
            );
          }
        }
      });
      // eslint-disable-next-line
    }, [mapStyle, addCustomLayers]);

    // Effect to toggle heatmap visibility
    useEffect(() => {
      if (mapRef.current?.getLayer('poi-heat')) {
        mapRef.current.setLayoutProperty(
          'poi-heat',
          'visibility',
          showHeatmap ? 'visible' : 'none',
        );
      }
    }, [showHeatmap]);

    // Effect to toggle rail types visibility and update filter
    useEffect(() => {
      if (mapRef.current?.getLayer('roads-layer')) {
        if (showRailTypes && !isSatelliteView) {
          const selectedTypes = Object.keys(selectedRailTypes).filter(
            (type) => selectedRailTypes[type as RailType],
          );
          if (selectedTypes.length > 0) {
            mapRef.current.setLayoutProperty(
              'roads-layer',
              'visibility',
              'visible',
            );
            mapRef.current.setFilter('roads-layer', [
              'in',
              'class',
              ...selectedTypes,
            ]);
          } else {
            // If no types are selected, hide the layer
            mapRef.current.setLayoutProperty(
              'roads-layer',
              'visibility',
              'none',
            );
          }
        } else {
          mapRef.current.setLayoutProperty('roads-layer', 'visibility', 'none');
        }
      }
    }, [showRailTypes, selectedRailTypes, isSatelliteView]);

    // Reset map to initial center and zoom
    const handleReset = () => {
      mapRef.current!.flyTo({ center: INITIAL_CENTER, zoom: INITIAL_ZOOM });
    };

    return (
      <div>
        <div className="dashboard">
          <div className="map-wrapper">
            <div ref={mapContainerRef} className="map-container" />
            <div className="map-overlay">
              {/* Single Row */}
              <div className="overlay-row">
                <div className="coordinates">
                  Long: {currentCenter[0].toFixed(4)} | Lat:{' '}
                  {currentCenter[1].toFixed(4)} | Zoom: {zoom.toFixed(2)}
                </div>
                <div className="controls">
                  <label className="control-label">
                    <input
                      type="checkbox"
                      checked={showHeatmap}
                      onChange={() => setShowHeatmap(!showHeatmap)}
                    />
                    Heatmap
                  </label>
                  <div className="control-group">
                    <label
                      className={`control-label ${
                        !showRailTypes || isSatelliteView ? 'disabled' : ''
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={showRailTypes}
                        onChange={() => setShowRailTypes(!showRailTypes)}
                        disabled={isSatelliteView}
                      />
                      Railways
                      <button
                        className={`rail-type-options-button ${
                          !showRailTypes || isSatelliteView ? 'disabled' : ''
                        }`}
                        onClick={() =>
                          setShowRailTypeOptions(!showRailTypeOptions)
                        }
                        disabled={!showRailTypes || isSatelliteView}
                      >
                        <span
                          className={`arrow ${
                            showRailTypeOptions ? 'up' : 'down'
                          }`}
                        ></span>
                      </button>
                    </label>
                    {/* Rail Type Options Dropdown */}
                    {showRailTypeOptions &&
                      showRailTypes &&
                      !isSatelliteView && (
                        <div className="rail-type-options">
                          {Object.keys(selectedRailTypes).map((railType) => (
                            <label key={railType} className="rail-type-label">
                              <input
                                type="checkbox"
                                checked={
                                  selectedRailTypes[railType as RailType]
                                }
                                onChange={() => {
                                  setSelectedRailTypes((prevState) => ({
                                    ...prevState,
                                    [railType]:
                                      !prevState[railType as RailType],
                                  }));
                                }}
                              />
                              {formatRailType(railType)}
                            </label>
                          ))}
                        </div>
                      )}
                  </div>
                  <label className="control-label">
                    Map Style:
                    <select
                      value={
                        mapStyle === 'mapbox://styles/mapbox/streets-v12'
                          ? 'streets'
                          : 'satellite'
                      }
                      onChange={(e) =>
                        setMapStyle(
                          e.target.value === 'streets'
                            ? 'mapbox://styles/mapbox/streets-v12'
                            : 'mapbox://styles/mapbox/satellite-streets-v12',
                        )
                      }
                    >
                      <option value="streets">Streets</option>
                      <option value="satellite">Satellite</option>
                    </select>
                  </label>
                  <button onClick={handleReset} className="reset-button">
                    <strong>Reset</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`side-panel ${showNotes ? 'open' : ''}`}>
          <div className="tab-header">
            <button
              className={`tab ${activeTab === 'details' ? 'active' : ''}`}
              onClick={() => setActiveTab('details')}
            >
              Details
            </button>
            <button
              className={`tab ${activeTab === 'notes' ? 'active' : ''}`}
              onClick={() => setActiveTab('notes')}
            >
              Notes
            </button>
            <button
              className="close-panel-button"
              onClick={() => setShowNotes(false)}
              aria-label="Close panel"
            >
              ×
            </button>
          </div>

          {selectedPoint ? (
            <div className="tab-content">
              <div
                className={`tab-pane ${activeTab === 'details' ? 'active' : ''}`}
              >
                <h2>
                  <b>POI DETAILS</b>
                </h2>
                <br />
                <table>
                  <tr>
                    <th>
                      <b>Rank</b>
                    </th>
                    <td>{selectedPoint.rank}</td>
                  </tr>
                  <tr>
                    <th>
                      <b>Latitude</b>
                    </th>
                    <td>{selectedPoint.Latitude}</td>
                  </tr>
                  <tr>
                    <th>
                      <b>Longitude</b>
                    </th>
                    <td>{selectedPoint.Longitude}</td>
                  </tr>
                  <tr>
                    <th>
                      <b>Radial Uncertainty (m)</b>
                    </th>
                    <td>{selectedPoint.stdError.toFixed(2)} m</td>
                  </tr>
                  <tr>
                    <th>
                      <b>Date First Appeared</b>
                    </th>
                    <td>
                      {selectedPoint.dateFirstAppeared.toLocaleDateString()}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <b>Company</b>
                    </th>
                    <td>{selectedPoint.company}</td>
                  </tr>
                  <tr>
                    <th>
                      <b>Status</b>
                    </th>
                    <td>{selectedPoint.status}</td>
                  </tr>
                </table>
              </div>
              <div
                className={`tab-pane ${activeTab === 'notes' ? 'active' : ''}`}
              >
                {noteAddedMessage && (
                  <div
                    className={`note-message ${noteAddedMessage.includes('Error') ? 'error' : 'success'}`}
                  >
                    {noteAddedMessage}
                  </div>
                )}
                <div className="notes-list">
                  {notes.map((note, index) => (
                    <div key={index} className="note-item">
                      <div className="note-meta">
                        <strong>{note.author}</strong>
                        {` (${note.timestamp.toUTCString()}):`}
                      </div>
                      <div className="note-text">{note.text}</div>
                    </div>
                  ))}
                </div>

                <div className="note-input-container">
                  <textarea
                    className="note-input"
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    placeholder="Add a new note..."
                    disabled={isNoteOnCooldown}
                  />
                  <button
                    className={`add-note-button ${isNoteOnCooldown ? 'disabled' : ''}`}
                    onClick={handleAddNote}
                    disabled={isNoteOnCooldown}
                  >
                    {isNoteOnCooldown
                      ? `Please wait for ${cooldownTime}s before adding the next note`
                      : 'Add'}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="empty-state">
              <h2>Click on a point of interest to view its information.</h2>
            </div>
          )}
        </div>
      </div>
    );
  },
);

Map.displayName = 'Map'; // Needed to pass the lint tests

export default Map;
