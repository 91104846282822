import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = (): JSX.Element => {
  const timestamp = new Date().toLocaleString();
  const requestedURL = window.location.href;
  const referrer = document.referrer || 'No referrer';

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '50px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f7f7f7',
        height: '100vh',
      }}
    >
      <h1 style={{ fontSize: '3rem', color: '#333', marginBottom: '20px' }}>
        404 - Page Not Found
      </h1>

      <p style={{ fontSize: '1.2rem', color: '#555', marginBottom: '30px' }}>
        Sorry, the page you are looking for does not exist or has been moved.
      </p>

      <p style={{ fontSize: '0.9rem', color: '#888', marginBottom: '10px' }}>
        Timestamp: <span style={{ fontWeight: 'bold' }}>{timestamp}</span>
      </p>
      <p style={{ fontSize: '0.9rem', color: '#888', marginBottom: '10px' }}>
        Referrer: <span style={{ fontWeight: 'bold' }}>{referrer}</span>
      </p>
      <p style={{ fontSize: '0.9rem', color: '#888', marginBottom: '30px' }}>
        Requested URL:{' '}
        <span style={{ fontWeight: 'bold' }}>{requestedURL}</span>
      </p>

      <Link
        to="/home"
        style={{
          display: 'inline-block',
          padding: '10px 20px',
          fontSize: '1rem',
          color: '#fff',
          backgroundColor: '#414288',
          borderRadius: '5px',
          textDecoration: 'none',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        Back to Home
      </Link>
    </div>
  );
};

export default NotFound;
