import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

// Paths to social media icons
const XIcon = '/assets/social_media_icon1.png';
const LinkedInIcon = '/assets/social_media_icon2.png';
const YouTubeIcon = '/assets/social_media_icon3.png';

// External URLs
const XURL = 'https://x.com/polychordcouk';
const LinkedInURL = 'https://www.linkedin.com/company/polychord-ltd/';
const YouTubeURL = 'https://www.youtube.com/@polychordltd5601';
const PrivacyPolicyURL = 'https://www.polychord.co.uk/privacy-policy.html';
const ContactPageURL = 'https://polychord.co.uk/index.html#contact';

function Navigation() {
  const currentYear = new Date().getFullYear();
  return (
    <Navbar expand="md" bg="default" variant="dark">
      <Container fluid className="d-flex flex-column align-items-center">
        {/* Center SM icons in navbar (open in new tabs) */}
        <Navbar id="basic-navbar-nav" className="align-content-center">
          <Nav className="d-flex justify-content-center">
            <Nav.Item>
              <a href={XURL} target="_blank" rel="noopener noreferrer">
                <img src={XIcon} alt="icon 1" className="me-5 py-3" />
              </a>
            </Nav.Item>
            <Nav.Item>
              <a href={LinkedInURL} target="_blank" rel="noopener noreferrer">
                <img src={LinkedInIcon} alt="icon 2" className="me-5 py-3" />
              </a>
            </Nav.Item>
            <Nav.Item>
              <a href={YouTubeURL} target="_blank" rel="noopener noreferrer">
                <img src={YouTubeIcon} alt="icon 3" className="py-3" />
              </a>
            </Nav.Item>
          </Nav>
        </Navbar>

        {/* Footer with links to Privacy Policy and Contact Page (open in new tabs) */}
        <div className="text-center mt-3 text-white fst-italic">
          <ul className="nav-links justify-content-center">
            <li>
              <a
                href={PrivacyPolicyURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>•</li>
            <li>© {currentYear} PolyChord Ltd</li>
            <li>•</li>
            <li>
              <a
                href={ContactPageURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Page
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </Navbar>
  );
}

function Footer() {
  return (
    <footer>
      <Navigation />
    </footer>
  );
}

export default Footer;
